import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

document.addEventListener("DOMContentLoaded", function () {
  gsap.registerPlugin(ScrollToPlugin);
  const scrollToWorkButton = document.getElementById("scrollToWork");

  scrollToWorkButton.addEventListener("click", function () {
    gsap.to(window, {
      scrollTo: {
        y: "#work",
        offsetY: 0,
      },
      duration: 1.5,
      ease: "power2.inout",
    });
  });
  const scrollToTopButton = document.getElementById("scrollToTop");

  scrollToTopButton.addEventListener("click", function () {
    gsap.to(window, {
      scrollTo: {
        y: "#top",
        offsetY: 0,
      },
      duration: 1.2,
      ease: "power2.inout",
    });
  });
});
