import { gsap } from "gsap";
import { Observer } from "gsap/Observer";

document.addEventListener("DOMContentLoaded", function () {
  gsap.registerPlugin(Observer);
  const mailtoButton = document.getElementById("mailto-button");

  const tl = gsap.timeline({ paused: true });

  tl.fromTo(
    mailtoButton,
    {
      opacity: 0,
      bottom: -50,
      duration: 0.5,
      ease: "power2.inOut",
    },
    { opacity: 1, bottom: 10, duration: 0.5, ease: "power2.inOut" },
  );

  Observer.create({
    target: window,
    type: "wheel,touch,scroll",
    onUp: () => {
      tl.play();
    },
    onDown: () => {
      tl.reverse();
    },
  });
});
