import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Observer } from "gsap/Observer";

document.addEventListener("DOMContentLoaded", () => {
  gsap.registerPlugin(ScrollTrigger, Observer);
  const scrollThumb = document.getElementById("scrollThumb");
  let hideTimeoutRef;

  function updateThumbPosition() {
    const progress = ScrollTrigger.getById("thumbTrigger").progress;
    const thumbTop = progress * (window.innerHeight - scrollThumb.clientHeight);
    const timeout = 3000;

    gsap.set(scrollThumb, { x: 0, opacity: 1 });
    gsap.to(scrollThumb, { top: thumbTop });
    clearTimeout(hideTimeoutRef);
    hideTimeoutRef = setTimeout(() => {
      gsap.fromTo(
        scrollThumb,
        { x: 0, opacity: 1 },
        {
          x: 50,
          opacity: 0,
          duration: 3,
        },
      );
    }, timeout);
  }

  ScrollTrigger.create({
    id: "thumbTrigger",
    start: "top top",
    end: "bottom bottom",
    onUpdate: updateThumbPosition,
    onEnter: () => {
      gsap.fromTo(scrollThumb, { x: 50, opacity: 0 }, { x: 0, opacity: 1 });
    },
    onScrubComplete: () => {
      gsap.fromTo(scrollThumb, { x: 0, opacity: 1 }, { x: 50, opacity: 0 });
    },
  });
  Observer.create({
    target: window,
    type: "wheel,touch",
    onUp: () => clearTimeout(hideTimeoutRef),
    onDown: () => clearTimeout(hideTimeoutRef),
  });
});
